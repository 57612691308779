      
    <!-- <div class="iframe-container">
        <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="100%" height="100%" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FEaXvUe3394u2kcpQkjVTKd%2Fcase-study-ppt%3Fpage-id%3D146%253A20129%26node-id%3D192-20604&hide-ui=1" allowfullscreen></iframe>
    </div> -->
<template>
  <div class="iframe-container">
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="100%" height="100%"
      src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FEaXvUe3394u2kcpQkjVTKd%2Fcase-study-ppt%3Fpage-id%3D146%253A20129%26type%3Ddesign%26node-id%3D1640-13081%26viewport%3D193%252C3552%252C0.18%26t%3DbjeaWImfHDwlSNfw-1%26scaling%3Dmin-zoom%26mode%3Ddesign&hide-ui=1"
      allowfullscreen></iframe>
  </div>
</template>
  
<script>



export default {
  

}
</script>
  
<style lang="scss">
.iframe-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
